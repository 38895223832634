:root{
    /* Colors */
    --main-color: #E8C278;
    --text-color: #242222;
    --light-pastel-color: #F5F1E7;

    /* Typographies */

    --font-family: "Comfortaa", sans-serif;

    --font-heading-size-desktop: 2.1vw;
    --font-heading-size-tab: 3.5vw;
    --font-heading-size-phone: 4.5vw;

    --font-main-size-desktop: 1.9vw;
    --font-main-size-tab: 3vw;
    --font-main-size-phone: 4vw;

    --font-secondary-size-desktop: 1.2vw;
    --font-secondary-size-tab: 2.5vw;
    --font-secondary-size-phone: 3vw;

    /* Layout */
    --page-desktop-padding: 3vw;
    --page-phone-padding: 6vw;

    --navigation-height: 10vh;
    --navigation-mobile-height: 8vh;
}
