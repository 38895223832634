@import "src/styles/mixins";

.container{
    margin-top: var(--navigation-height);

    @include respond(tab-port){
        margin-top: var(--navigation-mobile-height);
    }

    @include striped-background();
}
