@import "src/styles/mixins";

.container{
    display: none;

    @include respond(tab-port){
        display: block;
    }

    .burger{
        // !important to override helpers styling
        display: flex !important;
        flex-direction: column;
        justify-content: space-evenly;
        background: var(--main-color);

        margin-top: 50vh;
        transform: translateY(-50%);

        height: 20%;
        width: 100%;
        @include text-secondary();

        .language {

        }

        .options{
            color: var(--text-color);
        }
    }
}
