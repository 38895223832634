@import "src/styles/mixins";

.form{
    width: 100%;

    .headerText{
        @include block-header();
    }

    .inputs{
        display: flex;
        width: 100%;
        padding: 5vw 5vw;
        flex-direction: column;
        gap: 3vh;

        %text_inputs{
            border-radius: 1.5vw;
            padding: 2vh 2vw;
            color: var(--text-color);
            border: 0.2rem var(--text-color) solid;
            @include text-secondary();
        }

        .input{
            @extend %text_inputs;
            height: 5vh;

        }

        .textarea{
            @extend %text_inputs;
            height: 25vh;
            resize: none;
        }
    }


}
