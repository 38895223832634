@import "src/styles/mixins";

.container{
    --image-column-width: 1fr;
    --text-column-width: 1.5fr;

    padding: var(--page-desktop-padding);
    gap: var(--page-desktop-padding);

    display: flex;
    align-items: center;
    width: 100%;
    min-height: 70vh;

    @include respond(tab-port){
        display: block;
        padding: var(--page-phone-padding);
        min-height: 50vh;

        & > *:not(:last-child){
            margin-bottom: var(--page-phone-padding);
        }
    }

    &__imageRight{
        flex-direction: row-reverse;
    }

    &__imageSmall{
        .img{
            width: 25%;

            @include respond(tab-port){
                width: 40%;
            }
        }
    }

    .img{
        max-width: 50%;
        max-height: 60vh;
        object-fit: cover;
        aspect-ratio: 1/1;

        @include respond(tab-port){
            max-width: 100%;
            display: block;
            margin: 0 auto var(--page-phone-padding) auto;
        }
    }

    .img_fit{
        max-width: 50%;
        max-height: 60vh;
        object-fit: contain;
        aspect-ratio: 1/1;

        @include respond(tab-port){
            max-width: 100%;
            display: block;
            margin: 0 auto var(--page-phone-padding) auto;
        }
    }

    .image_link{
        cursor: pointer;

        .image{
            max-width: 100%;
            max-height: 60vh;
            object-fit: cover;
            aspect-ratio: 1/1;

            @include respond(tab-port){
                max-width: 100%;
                display: block;
                margin: 0 auto var(--page-phone-padding) auto;
            }
        }
    }

    .link {
        cursor: pointer;
        text-decoration: none;
        color: inherit;
    }
    
    .link:hover {
        text-decoration: underline;
    }

    .text_container{
        @include flex-center();
        flex-direction: column;
        gap: var(--page-desktop-padding);
        flex: 1;
        padding: 0 5vw;

        .header{
            font-weight: bold;
        }

        .subheader{
            font-weight: bold;
            font-style: italic;
        }
    }

    .text_container p {
        text-align: justify;
    }

    .text_container .link p {
        text-align: center;
    }
}
