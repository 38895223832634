@import "src/styles/mixins";

.grid_4 {
    $gap: 5vw;
    $columns: 4;

    @include display-grid($columns, $gap);
}

.grid_5 {
    $gap: 3vw;
    $columns: 5;

    @include display-grid($columns, $gap);
}


