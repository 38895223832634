@import "variables";
// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) { @content };    //600px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content };     //900px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content };    //1200px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) and
        (-webkit-max-device-pixel-ratio: 1) { @content };    //1800
    }
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

//TYPOGRAPHY

@mixin text-heading {
    font-size: var(--font-heading-size-desktop);

    @include respond(tab-port) {
        font-size: var(--font-heading-size-tab);
    }
    @include respond(phone) {
        font-size: var(--font-heading-size-phone);
    }
}
@mixin text-main {
    font-size: var(--font-main-size-desktop);

    @include respond(tab-port) {
        font-size: var(--font-main-size-tab);
    }
    @include respond(phone) {
        font-size: var(--font-main-size-phone);
    }
}

@mixin text-secondary {
    font-family: var(--font-family);
    font-size: var(--font-secondary-size-desktop);

    @include respond(tab-port) {
        font-size: var(--font-secondary-size-tab);
    }
    @include respond(phone) {
        font-size: var(--font-secondary-size-phone);
    }
}

@mixin display-grid($columns, $gap){
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat($columns, 1fr);
    align-items: flex-start;

    gap: $gap;
    padding: $gap;

    @include respond(tab-port){
        grid-template-columns: repeat(2, 1fr);
    }
}

@mixin flex-between(){
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin block-header(){
    font-weight: bold;
    text-align: center;
    margin-bottom: 2vh;
}

@mixin striped-background(){
    & > *:nth-child(2n){
        background: var(--light-pastel-color);
    }
}

