@import "src/styles/mixins";

.container {
    @include flex-center();
    flex-direction: column;
    height: 100%;

    .image {
        border-radius: 5%;
        overflow: hidden;

        width: 100%;
        height: 45vh;
        object-fit: cover;
        margin-bottom: 4vh;
    }

    .header {
        flex: 1;
        margin-bottom: 2vh;
        font-weight: bold;
        text-align: center;
        word-spacing: 100vw;
        @include flex-center();
    }

    .description {
        @include text-secondary();
    }

    .icons_container{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        color: var(--text-color);

        img{
            --size: 3vw;

            height: var(--size);
            width: var(--size);

            @include respond(tab-port){
                --size: 7vw;
            }
        }
    }
}







