@import "src/styles/mixins";

.container {
    @include flex-center();
    flex-direction: column;
    text-align: center;


    .image {
        border-radius: 50%;
        overflow: hidden;

        max-height: 20vh;
        max-width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        margin-bottom: 3vh;
    }

    .header {
        margin-bottom: 1vh;
        font-weight: bold;
    }

    .description {
        @include text-secondary();
    }
}







