@import "src/styles/mixins";

.button{
    @include flex-center();
    margin: 0 auto;

    border-radius: 2rem;
    padding: 2vh 2vw;
    min-width: 15vw;

    background: var(--main-color);
    color: white;
    cursor: pointer;

    @include respond(tab-port){
        border-radius: 1rem;
        padding: 2vh 5vw;
    }
}
