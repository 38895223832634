@import "mixins";
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;600&display=swap');

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    // This defines what 1rem is
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
}

body {
    scroll-behavior: smooth;
    box-sizing: border-box;

    overflow-x: hidden;
    scrollbar-width: none; /* Firefox */

    max-width: 100vw;

    @include text-main();
    font-family: var(--font-family);
    color: var(--text-color);

    @include respond(big-desktop){
        max-width: 60%;
        margin: 0 auto;
    }
}
