@import "src/styles/mixins";
.container{
    height: 55vh;
    display: flex;
    align-items: center;

    @include respond(tab-port){
        height: 45vh;
    }

    .image_slide{
        width: 40vw;
        height: 45vh;
        border-radius: 5px;
        object-fit: cover;

        @include respond(tab-port){
            width: 100vw;
            height: 35vh;
        }
    }

    .image_fit{
        width: 40vw;
        height: 45vh;
        border-radius: 5px;
        object-fit: contain;

        @include respond(tab-port){
            width: 100vw;
            height: 35vh;
        }
    }
}



