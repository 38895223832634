@import "src/styles/mixins";

.container{
    margin-top: 15vh;
    @include striped-background();

    @include respond(tab-port){
        margin-top: var(--navigation-mobile-height);
    }
}
