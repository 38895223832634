@import "src/styles/mixins";
.container{
    height: 100%;
    width: 100%;

    .headerText{
        text-align: center;
        margin-bottom: 5vh;
        font-weight: bold;
    }

    .image_link{
        display: block;
        width: 100%;
        cursor: pointer;

        .image{
            width: 100%;
        }
    }

}



