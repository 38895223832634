@import "src/styles/mixins";

.page{
    margin-top: 10vh;
    @include striped-background();

    .container{
        display: flex;
        gap: 5vw;
        padding: var(--page-desktop-padding);

        @include respond(tab-port){
            display: block;
            padding: var(--page-phone-padding);
        }

        .sub_container{
            width: 40%;

            @include respond(tab-port){
                width: 80%;
                margin: 0 auto 5vh auto;
            }
        }
    }
}
